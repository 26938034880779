let YTIFrameAPIResolver;
const YTIFrameAPILoaded = new Promise(
  resolve => (YTIFrameAPIResolver = resolve)
);

const loadYTIFrameAPI = () => {
  const YTIFrameAPIScript = document.createElement('script');
  YTIFrameAPIScript.src = 'https://www.youtube.com/iframe_api';

  document.body.appendChild(YTIFrameAPIScript);

  window.onYouTubeIframeAPIReady = function () {
    YTIFrameAPIResolver();
    delete window.onYouTubeIframeAPIReady;
  };
};

class YoutubePlayer extends HTMLElement {
  static ALL_VIDEO_PLAYERS = [];

  id = null;
  player = null;
  hasPoster = false;

  onPlayerReady() {
    if (this.hasPoster) {
      this.player.playVideo();
      this.classList.add('is-playing');
      setTimeout(() => {
        this.classList.remove('is-loading');
      }, 800);
    }
  }

  onPlayerStateChange(event) {
    if (event.data == YT.PlayerState.PLAYING) {
      YoutubePlayer.ALL_VIDEO_PLAYERS.forEach(player => {
        if (player === this.player) return;
        player.pauseVideo();
        player.i.closest('video-player')?.classList.remove('is-playing');
        player.i.closest('video-player')?.classList.remove('is-loading');
      });
    }
  }

  async prepareVideo() {
    await YTIFrameAPILoaded;

    this.player = new YT.Player(this.id, {
      videoId: this.getAttribute('video-id'),
      playerVars: {
        playsinline: 1,
        allowfullscreen: 1,
      },
      events: {
        onReady: () => this.onPlayerReady(),
        onStateChange: e => this.onPlayerStateChange(e),
      },
    });

    YoutubePlayer.ALL_VIDEO_PLAYERS.push(this.player);
  }

  playVideo() {
    if (this.player) return this.onPlayerReady();
    this.classList.add('is-loading');
    this.prepareVideo();
  }

  connectedCallback() {
    const player = document.createElement('DIV');
    player.setAttribute('id', this.id);
    this.appendChild(player);

    if (window.getComputedStyle(this).backgroundImage !== 'none') {
      const button = document.createElement('BUTTON');
      button.setAttribute('role', 'button');
      button.setAttribute('tabindex', '0');
      this.hasPoster = true;
      this.classList.add('has-poster');
      this.appendChild(button);
      button.addEventListener('click', () => this.playVideo());
    } else this.prepareVideo();
    this.setAttribute('component', 'connected');
  }

  constructor() {
    super();
    this.id = `video-player__${Math.floor(Math.random() * Date.now() * 100)}`;
  }
}

export default function () {
  loadYTIFrameAPI();
  window.customElements.define('youtube-player', YoutubePlayer);
}
