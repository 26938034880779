import { camelCase, addEvents } from '~utils';

export default class {
  constructor({
    trigger,
    content,
    container,
    relations,
    scrollTest,
    offsetElements,
    externalEvents,
    eventString,
    timing,
    props,
    media,
  }) {
    this.#trigger = trigger;
    this.#content = content;

    container ? (this.#container = container) : false;
    relations ? (this.#relations = relations) : false;
    media ? (this.#media = media) : false;

    scrollTest ? (this.#scrollTest = scrollTest) : false;
    this.#offsetElements = offsetElements
      ? typeof offsetElements === 'string'
        ? [...document.querySelectorAll(offsetElements)]
        : typeof offsetElements[Symbol.iterator] === 'function'
        ? [...offsetElements]
        : [offsetElements]
      : [];

    externalEvents ? (this.#externalEvents = externalEvents) : false;
    !!eventString ? (this.#eventString = eventString) : false;

    timing ? (this.#timing = timing) : false;
    props
      ? ((this.#properties.props = props.map(camelCase)),
        (this.#properties.default = false))
      : false;

    this.#initialize();
    return this;
  }

  #trigger; // required element to click on that toggles the active state
  #content; // required element whose styles are animated

  #container; // optional element that also gets the active flag
  #relations = []; // array of toggle instances that close when another one opens
  #media; // media query object which, if it exists, only allows toggle to run when it matches

  #on = [];

  #scrollTest = () => false; // function (or bool) used to determine if we should scroll to the content on toggle
  #offsetElements = []; // array of elements whose vertical height should be accounted for if scrollTest = true

  #externalEvents = false; //set to true to handle event triggering externally
  #eventString = 'click.stop.prevent enter.stop.prevent'; // event string to pass to addEvents

  #properties = {
    //properties to toggle (default is height from 0 to auto)
    default: true,
    props: ['height'],
  };
  #timing = {
    //animation timing properties
    duration: 350,
    easing: 'ease',
  };

  #isActive = false; // active state of the toggle
  #id = (() =>
    //unique data id applied to the trigger and content for scoping css and for relating labels to content
    `data-${Math.random()
      .toString(36)
      .split('')
      .filter((value, index, self) => self.indexOf(value) === index)
      .join('')
      .substr(2, 8)}`)();

  #make_styles() {
    const style = document.createElement('style');
    style.textContent = `
      [${this.#id}] {
        overflow: hidden;
        height: 0;
        visibility: hidden;
        opacity: 0;
        transition-property: visibility opacity;
        transition-duration: ${this.#timing.duration}ms;
        transition-timing-function: ${this.#timing.easing};
      }
      [${this.#id}][data-active] {
        height: auto;
        opacity: 1;
        visibility: visible;
      }`;
    document.head.insertAdjacentElement('beforeend', style);
  }
  #get_property_values(c) {
    const styles = getComputedStyle(c);
    return this.#properties.props.reduce((values, prop) => {
      values[prop] = styles[prop];
      return values;
    }, {});
  }
  #get_offset() {
    return this.#offsetElements.reduce(
      (offset, o) => ((offset += o.offsetHeight), offset),
      0
    );
  }
  #animate(el, keyframes) {
    let animation = el.animate(keyframes, this.#timing);
    if (this.#on.toggle !== null) {
      animation.onfinish = () =>
        this.#on.forEach(cb => cb(this.#isActive, this));
    }
    return animation;
  }
  #scroll(t) {
    const top = t.getBoundingClientRect().top - this.#get_offset();
    scrollBy({ top, behavior: 'smooth' });
  }
  #cleanup_container() {
    const c = this.#container;
    if (!c) return;
    c.removeAttribute('toggler', '');
    c.removeAttribute('data-active');
  }
  #cleanup_trigger() {
    const t = this.#trigger;
    t.removeAttribute('toggler');
    t.removeAttribute('id');
    t.removeAttribute('aria-expanded');
    t.removeAttribute('aria-labelledby');
    t.removeAttribute('aria-controls');
    t.removeAttribute('tabindex');
    t.removeAttribute('role');
    t.removeAttribute('data-active');
  }
  #cleanup_content() {
    const c = this.#content;
    c.removeAttribute('toggler');
    c.removeAttribute(this.#id);
    c.removeAttribute('id');
    c.removeAttribute('aria-labelledby');
    c.removeAttribute('role');
    c.removeAttribute('data-active');
  }
  #process_container() {
    const c = this.#container;
    if (!c) return;
    c.setAttribute('toggler', '');
    if (this.#isActive) c.setAttribute('data-active', '');
  }
  #process_trigger(bind_events = true) {
    const t = this.#trigger;
    const t_id = `${this.#id}-trigger`;
    const c_id = `${this.#id}-content`;

    t.setAttribute('toggler', '');
    t.setAttribute('id', t_id);
    t.setAttribute('aria-expanded', 'false');
    t.setAttribute('aria-labelledby', t_id);
    t.setAttribute('aria-controls', c_id);
    t.setAttribute('tabindex', '0');
    t.setAttribute('role', 'button');
    if (this.#isActive) t.setAttribute('data-active', '');

    if (!bind_events) return;

    if (!this.#externalEvents) {
      if (this.#eventString.includes('hover')) {
        addEvents(t, this.#eventString, {
          in: this.toggle,
          out: this.toggle,
        });
      } else {
        addEvents(t, this.#eventString, this.toggle);
      }
    }
  }
  #process_content() {
    const c = this.#content;
    const t_id = `${this.#id}-trigger`;
    const c_id = `${this.#id}-content`;

    c.setAttribute('toggler', '');
    c.setAttribute(this.#id, '');
    c.setAttribute('id', c_id);
    c.setAttribute('aria-labelledby', t_id);
    if (this.#isActive) c.setAttribute('data-active', '');

    /*
     * role=region breaks the default semantics of these elements
     * and the role is inferred for these elements
     */
    const nonRegionNodes = ['UL', 'OL', 'DL', 'LI'];
    if (!nonRegionNodes.includes(c.nodeName)) {
      c.setAttribute('role', 'region');
    }
  }
  #process_media() {
    const handleMedia = ({ matches }) => {
      if (matches) {
        this.#process_container();
        this.#process_trigger(false);
        this.#process_content();
      } else {
        this.#cleanup_container();
        this.#cleanup_trigger();
        this.#cleanup_content();
      }
    };
    addEvents(this.#media, 'change', handleMedia);
    handleMedia(this.#media);
  }
  #initialize() {
    this.#properties.default ? this.#make_styles() : false;
    this.#process_container();
    this.#process_trigger();
    this.#process_content();
    if (this.#media) this.#process_media();
  }
  //Public Methods
  toggle = async () => {
    const container = this.#container;
    const trigger = this.#trigger;
    const content = this.#content;
    const keyframes = [];
    if (this.#media && !this.#media.matches) return Promise.resolve();
    const do_toggle = () => {
      this.#isActive = !this.#isActive;
      keyframes.push(this.#get_property_values(content));
      container
        ? container.toggleAttribute('data-active', this.#isActive)
        : false;
      trigger.toggleAttribute('data-active', this.#isActive);
      trigger.setAttribute('aria-expanded', this.#isActive);
      content.toggleAttribute('data-active', this.#isActive);
      keyframes.push(this.#get_property_values(content));
      this.#animate(content, keyframes);
      this.#scrollTest() ? this.#scroll(trigger) : false;
      return true;
    };

    if (this.#relations.length) {
      await this.#relations.reduce(async (prevPromise, relation) => {
        await prevPromise;
        if (relation === this) return Promise.resolve();
        else return relation.close();
      }, Promise.resolve());
    }

    do_toggle();
  };
  close() {
    return new Promise(resolve => {
      if (this.#media && !this.#media.matches) return resolve();
      const container = this.#container;
      const trigger = this.#trigger;
      const content = this.#content;
      const keyframes = [];
      const do_toggle = () => {
        if (!this.#isActive) return resolve(), true;
        this.#isActive = false;
        keyframes.push(this.#get_property_values(content));
        container ? container.removeAttribute('data-active') : false;
        trigger.removeAttribute('data-active');
        trigger.setAttribute('aria-expanded', false);
        content.removeAttribute('data-active');
        keyframes.push(this.#get_property_values(content));
        this.#animate(content, keyframes).onfinish = resolve;
        this.#scrollTest() ? this.#scroll(trigger) : false;
        return true;
      };

      do_toggle();
    });
  }
  open() {
    return new Promise(resolve => {
      if (this.#media && !this.#media.matches) return resolve();
      const container = this.#container;
      const trigger = this.#trigger;
      const content = this.#content;
      const keyframes = [];
      const do_toggle = () => {
        if (this.#isActive) return resolve(), true;
        this.#isActive = true;
        keyframes.push(this.#get_property_values(content));
        container ? container.setAttribute('data-active', '') : false;
        trigger.setAttribute('data-active', '');
        trigger.setAttribute('aria-expanded', true);
        content.setAttribute('data-active', '');
        keyframes.push(this.#get_property_values(content));
        this.#animate(content, keyframes).onfinish = resolve;
        this.#scrollTest() ? this.#scroll(trigger) : false;
        return true;
      };

      do_toggle();
    });
  }
  onToggle(callback) {
    if (typeof callback === 'function') {
      this.#on.push(callback);
    }
  }
}
