export default function (
  { tag = 'div', attributes = {}, html = undefined, text = undefined },
  NS
) {
  const el = NS
    ? document.createElementNS(NS, tag)
    : document.createElement(tag);

  Object.keys(attributes).forEach(key => {
    el.setAttribute(key, attributes[key]);
  });

  if (html) {
    if (Array.isArray(html)) {
      html.forEach(e => el.insertAdjacentElement('beforeend', e));
    } else if (typeof html === 'object') {
      el.insertAdjacentElement('beforeend', html);
    } else {
      el.insertAdjacentHTML('beforeend', html);
    }
  }

  if (text !== undefined) {
    el.textContent = text;
  }
  return el;
}
