import { retach } from '~plugins';
import { screen, addEvents } from '~utils';

export default function () {
  const inner = this.querySelector('.header__inner');
  const utilItems = [...this.querySelectorAll('.header__util-item')];
  const navItems = [...this.querySelectorAll('.header__nav-item')];
  const navSubItems = [...this.querySelectorAll('.header__nav-group')];
  const media = screen.md();
  const mediaDown = screen.md_dn();

  const drawerToggle = this.querySelector('.header__toggle');
  const drawer = this.querySelector('.header__drawer');
  if (!drawer) return;

  const drawerList = drawer.querySelector('.header__drawer-list');

  const toggleMenu = (item, close = false) => {
    const subMenu = item.querySelector(
      '.header__nav-menu, .header__nav-group-list'
    );
    const parentMenu = item.querySelector(
      '.header__nav-menu, .header__nav-group-list'
    );
    if (subMenu) subMenu.scrollTo(0, 0);
    if (parentMenu) parentMenu.scrollTo(0, 0);
    drawerList.scrollTo(0, 0);
    item.classList[close ? 'remove' : 'toggle']('is-active');
  };


  const toggleDrawer = (close = false) => {
    const offset = this.offsetHeight
    drawer.style.top = `${offset}px`;
    drawer.style.height = `calc(100% - ${offset}px)`;

    this.classList[close ? 'remove' : 'toggle']('is-open');

    if (this.classList.contains('is-open')) {
      drawerToggle.classList.add('is-open');
      drawer.classList.add('is-open');
    } else {
      drawerToggle.classList.remove('is-open');
      drawer.classList.remove('is-open');
    }
    closeAllActive({});
  };

  const closeAllActive = e => {
    const target = e.target;
    const active = [...this.querySelectorAll('.is-active')];
    active.forEach(item => {
      if (!target || target === window) toggleMenu(item, true);
      else {
        if (!item.contains(target)) toggleMenu(item, true);
      }
    });
  };

  const bindUtilEvents = item => {
    const trigger = item.querySelector('button.header__util-label');
    const back = item.querySelector('button.header__nav-back');
    const menu = item.querySelector('.header__util-menu');

    if (trigger) {
      addEvents(item, 'tab', () => {
        if (mediaDown.matches) return;
        if (item.classList.contains('is-active')) toggleMenu(item);
      });

      addEvents(item, 'keydown', e => {
        if (mediaDown.matches) return;

        if (e.key === 'Enter' || e.key === ' ') {
          toggleMenu(item);
          if (item.classList.contains('is-active')) {
            let first = menu.querySelector('a.header__util-menu-link');
            setTimeout(() => first.focus(), 200);
          } else trigger.focus();
        }

        if (e.key !== 'ArrowDown' && e.key !== 'ArrowUp') return;

        let timeout = 0;
        if (!item.classList.contains('is-active')) {
          timeout = 200;
          toggleMenu(item);
        }

        let items = [...menu.querySelectorAll('a.header__util-menu-link')];
        if (e.key === 'ArrowUp') items = items.reverse();

        let current = items.find(i => i.matches(':focus'));
        let next = items[items.indexOf(current) + 1];
        if (next) setTimeout(() => next.focus(), timeout);
        else {
          toggleMenu(item);
          trigger.focus();
        }
      });

      addEvents(trigger, 'click.prevent', e => {
        if (e.screenX === 0 && e.screenY === 0 && media.matches) return; // use screenX and screenY because e.pointerType is null in Firefox
        toggleMenu(item);
      });
    }

    if (back) {
      addEvents(back, 'click.prevent', () => {
        toggleMenu(item, true);
      });
    }
  };
  
  const adjustDesktopMenuPosition = (item, menu) => {
    if (item.classList.contains('is-active')) return;
    menu.style.left = '';
    const windowWidth = window.innerWidth;
    const menuWidth = menu.offsetWidth;
    const menuLeft = menu.getBoundingClientRect().left;

    if (media.matches && windowWidth < menuWidth + menuLeft) {
      const adjust = menuWidth + menuLeft - windowWidth;
      menu.style.left = `calc(50% - ${adjust}px)`;
    }
  };

  const bindNavEvents = item => {
    const trigger = item.querySelector('button.header__nav-label');
    const menu = item.querySelector('.header__nav-menu');
    const back = item.querySelector('button.header__nav-back');
    let grid;

    if (trigger) {
      if (item.classList.contains('header__nav-item--full')) {
        grid = item.querySelector('.header__nav-grid');
      }

      addEvents(item, 'tab', () => {
        if (mediaDown.matches) return;
        if (item.classList.contains('is-active')) toggleMenu(item);
      });

      addEvents(item, 'keydown', e => {
        if (mediaDown.matches) return;
        if (e.key === 'Enter' || e.key === ' ') {
          if (menu) adjustDesktopMenuPosition(item, menu);
          toggleMenu(item);
          if (grid) resizeAllGridItems(grid);
          if (item.classList.contains('is-active')) {
            let first = menu.querySelector('a:not(.header__nav-back *)');
            setTimeout(() => first.focus(), 200);
          } else trigger.focus();
        }

        if (!e.key.includes('Arrow')) return;

        if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
          let timeout = 0;
          if (!item.classList.contains('is-active')) {
            timeout = 200;
            if (menu) adjustDesktopMenuPosition(item, menu);
            toggleMenu(item);
            if (grid) resizeAllGridItems(grid);
          }

          let items = [...menu.querySelectorAll('a:not(.header__nav-back *)')];
          if (e.key === 'ArrowUp') items = items.reverse();

          let current = items.find(i => i.matches(':focus'));
          let next = items[items.indexOf(current) + 1];
          if (next) setTimeout(() => next.focus(), timeout);
          else {
            toggleMenu(item);
            trigger.focus();
          }
          return;
        }

        if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
          if (!grid) return;
          if (!item.classList.contains('is-active')) return;

          let items = [...menu.querySelectorAll('a:not(.header__nav-back *)')];
          let current = items.find(i => i.matches(':focus'));

          let currentColumnIndex;
          let columns = [
            ...grid.querySelectorAll('.header__nav-grid-cell'),
          ].reduce((columns, columnEl, i, arr) => {
            let usePreviousColumn =
              i > 0 && columnEl.dataset.column === arr[i - 1].dataset.column;
            let column = usePreviousColumn ? columns[columns.length - 1] : [];

            column.push(
              ...columnEl.querySelectorAll('a:not(.header__nav-back *)')
            );

            if (!usePreviousColumn) columns.push(column);

            if (column.includes(current))
              currentColumnIndex = columns.indexOf(column);
            return columns;
          }, []);

          if (e.key === 'ArrowLeft') {
            if (currentColumnIndex === 0) return;
            let itemIndex = columns[currentColumnIndex].indexOf(current);
            let nextColumn = columns[currentColumnIndex - 1];
            if (nextColumn.length >= itemIndex + 1)
              nextColumn[itemIndex].focus();
            else nextColumn[nextColumn.length - 1].focus();
          }

          if (e.key === 'ArrowRight') {
            if (currentColumnIndex === columns.length - 1) return;
            let itemIndex = columns[currentColumnIndex].indexOf(current);
            let nextColumn = columns[currentColumnIndex + 1];

            if (nextColumn.length >= itemIndex + 1)
              nextColumn[itemIndex].focus();
            else nextColumn[nextColumn.length - 1].focus();
          }
        }
      });

      addEvents(trigger, 'click.prevent', e => {
        if (e.screenX === 0 && e.screenY === 0 && media.matches) return; // use screenX and screenY because e.pointerType is null in Firefox
        if (menu) adjustDesktopMenuPosition(item, menu);
        toggleMenu(item);
        if (grid) resizeAllGridItems(grid);
      });
    }
    if (back) {
      const viewAll = back.querySelector('a');
      if (viewAll) {
        addEvents(viewAll, 'click.stop enter.stop', () => false);
      }
      addEvents(back, 'click.prevent enter.prevent', () => {
        toggleMenu(item, true);
      });
    }
  };

  const bindSubNavEvents = item => {
    const trigger = item.querySelector('.header__nav-group-title');
    const back = item.querySelector('.header__nav-back');
    if (trigger) {
      addEvents(trigger, 'click enter', e => {
        if (mediaDown.matches) {
          e.preventDefault();
          toggleMenu(item);
        }
      });
    }
    if (back) {
      const viewAll = back.querySelector('a');
      if (viewAll) {
        addEvents(viewAll, 'click.stop enter.stop', () => false);
      }
      addEvents(back, 'click.prevent enter.prevent', () => {
        toggleMenu(item, true);
      });
    }
  };

  const bindDrawerEvents = () => {
    addEvents(drawerToggle, 'click.prevent enter.prevent', () =>
      toggleDrawer()
    );

    if (navItems) {
      retach(navItems, {
        destination: drawerList,
        mediaQuery: mediaDown,
        movedClass: 'header__drawer-item',
      });
    }
    if (utilItems) {
      retach(utilItems, {
        destination: drawerList,
        mediaQuery: mediaDown,
        movedClass: 'header__drawer-item',
      });
    }
  };

  const initialize = () => {
    utilItems.forEach(bindUtilEvents);
    navItems.forEach(bindNavEvents);
    navSubItems.forEach(bindSubNavEvents);
    bindDrawerEvents();
    addEvents(document, 'click.stop, keydown.stop', e => {
      closeAllActive(e);
    });
    window.addEventListener('resize', e => {
      if (media.matches) closeAllActive(e);
      toggleDrawer(true);
    });
  };

  initialize();

  function resizeAllGridItems(grid) {
    const items = [...grid.querySelectorAll('.header__nav-grid-cell')];
    items.forEach(item => (item.style.gridRowEnd = ''));
    items.forEach(item => resizeGridItem(grid, item));
    items.forEach(item => labelColumns(grid, item));
  }

  function resizeGridItem(grid, item) {
    const gridStyles = getComputedStyle(grid);
    const content = item.querySelector('.header__nav-grid-content');
    const contentRect = content.getBoundingClientRect();
    const rowHeight =
      parseInt(gridStyles.getPropertyValue('grid-auto-rows')) || 10;
    const rowGap = parseInt(gridStyles.getPropertyValue('grid-row-gap')) || 0;
    const rowSpan =
      Math.ceil((contentRect.height + rowGap) / (rowHeight + rowGap)) + 1;
    item.style.gridRowEnd = `span ${rowSpan}`;
  }

  function labelColumns(grid, item) {
    const gridStyles = getComputedStyle(grid);
    const columnWidths = gridStyles
      .getPropertyValue('grid-template-columns')
      .split(' ')
      .map(w => parseFloat(w));
    const totalColumns = columnWidths.length;
    const gapWidth = parseInt(gridStyles.getPropertyValue('column-gap') || 0);
    const gridInternalWidth = Math.floor(
      [
        ...columnWidths,
        ...Array(columnWidths.length - 1).fill(gapWidth),
      ].reduce((sum, item) => (sum += item), 0)
    );
    const gridInternalLeftOffset = Math.floor(
      (grid.offsetWidth - gridInternalWidth) / 2
    );
    const itemLeft = item.offsetLeft;

    let offsetCheck = gridInternalLeftOffset;
    let column = 0;

    columnWidths.some(columnWidth => {
      let currentCheck = offsetCheck;
      offsetCheck = Math.round(offsetCheck + columnWidth + gapWidth);
      column = column + 1;
      if (itemLeft <= currentCheck + 1 && itemLeft >= currentCheck - 1) {
        item.setAttribute('data-column', column);
        return true;
      }
      return false;
    });
    if (column === totalColumns) item.classList.add('is-last-column');
    else item.classList.remove('is-last-column');
  }
}
