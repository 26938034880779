export default function (name, value, days) {
	if (name === undefined || typeof name !== "string") return;
	if (value === undefined) return;
	if (typeof days !== "number") return;
	var expires = '';
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = '; expires=' + date.toUTCString();
	}
	document.cookie = name + '=' + (value || '') + expires + '; path=/';
}