const camelCase = s => s.replace(/-./g, x => x[1].toUpperCase());
export default function (el) {
  return el.getAttributeNames().reduce((props, name) => {
    if (!name.startsWith(':')) return props;

    let value = el.getAttribute(name);

    try {
      if (!isNaN(value)) value = Number(value);
      else if (value.toLowerCase() === 'true') value = true;
      else if (value.toLowerCase() === 'false') value = false;
      else value = JSON.parse(value);
    } finally {
      el.removeAttribute(name);
      name = camelCase(name.replace(':', '').replace('data-', ''));
      props[name] = value;
      el.removeAttribute(name);
      return props;
    }
  }, {});
}
