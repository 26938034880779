export default function () {
  if (Array.prototype.spread !== undefined) {
    console.warn(
      '<BM Polyfill> Array.spread is over writing default browser behavior. This polyfill should be renamed, or removed if browser functionality replaces it.'
    );
  }
  Array.prototype.spread = function (fn) {
    const context = this;
    if (!Array.isArray(context))
      return Error(`${context} is not of type <Array>`);

    const region = {
      first: context.at(0),
      second: context.at(1),
      secondLast: context.at(-2),
      last: context.at(-1),
    };
    fn.call(window, region, context);
  };
  return Promise.resolve();
}
