import { browser } from '~utils';
import { spread, regionalEach } from '~utils/polyfills';

const polyfill_ios = () => {
  const is_ios = browser.os === 'iOS' || browser.os === 'Mac OS';
  const result = [Promise.resolve()];

  if (is_ios) {
    result.push(
      import(
        /* webpackMode: "lazy-once" */
        `${'construct-style-sheets-polyfill'}`
      )
    );
  }

  return Promise.all(result);
};

const polyfill_custom = () => {
  return Promise.all([spread(), regionalEach()]);
};

export default () => Promise.all([polyfill_ios(), polyfill_custom()]);
