import A11yDialog from 'a11y-dialog'
import { addEvents } from '~utils';
export default function () {
    const playButton = this.querySelector('.video__play');
    let dialog
    let dialogId
    let videoEl
    if(playButton === null) return;
    const createDialogEl = (id, content) => { 
        const dialogEl = `<div class="dialog__container" id="video-${id}" aria-labelledby="video-title-${id}" aria-hidden="true" aria-modal="true" tabindex="-1" role="dialog">
            <div class="dialog__overlay" data-a11y-dialog-hide=""></div>
            <div class="dialog__content dialog__content--overflow-y" role="document">
            <button class="dialog__close" type="button" data-a11y-dialog-hide="" aria-label="Close dialog"><i class="glyph-remove"></i></button>
            <p class="dialog__title" id="video-title-${id}">Texas Association of Counties - Video Modal</p>
            <figure class="dialog__media">
                ${content}
            </figure>
            </div>
        </div>`
        document.body.insertAdjacentHTML('beforeend', dialogEl)
    }
    
    const setAttributes = (id) => {
        playButton.setAttribute('data-a11y-dialog-show', `video-${id}`)
        playButton.setAttribute('aria-controls', `video-${id}`)
        playButton.setAttribute('aria-expanded', 'false')
        playButton.setAttribute('aria-label', 'Open dialog')
    }
    const openDialog = (dialogId, videoEl) => {
        createDialogEl(dialogId, videoEl)
        const element = document.getElementById(`video-${dialogId}`)
        dialog = new A11yDialog(element)
        dialog.on('hide', function (element, event) {
          // Do something when dialog gets hidden
          // Note: closer is `event.currentTarget`
          element.remove()
        })
        dialog.show()
      }
    if (playButton.dataset.youtubeSrc) {
        videoEl = 
        `
        <iframe style="--width: 670; --height: 377;" width="670" height="377" src="https://www.youtube.com/embed/${playButton.dataset.youtubeSrc}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe>
        `
        dialogId = playButton.dataset.youtubeSrc
        setAttributes(dialogId)
        createDialogEl(dialogId, videoEl)
        const element = document.getElementById(`video-${dialogId}`)
        dialog = new A11yDialog(element)
        // youtube dialog
    }
    addEvents(playButton, 'click enter', () => { openDialog(dialogId, videoEl) })
    // add events
}