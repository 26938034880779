/******************
 * Media queries: use these in your scripts, based on scss breakpoints
 * If IE11 is a requirement, replace this block with the commented version below, and use hard coded values.
 * Usage ex1:
    if (screen.default.matches) {
      //screen is bigger then 1100px
    }
  * Usage ex2:
    const paragraph = document.querySelector('p')

    function makeBlueOnDesktop(e) {
      if (e.matches) {
        //screen query matches, make paragraph blue
        paragraph.style.color = 'blue'
      }
      else {
        //screen query does not match, remove blue coloring
        paragraph.style.color = ''
      }
    }

    this.$screen.default.addListener(makeBlueOnDesktop)
******************/

const doc = getComputedStyle(document.documentElement);
export default {
  xs: () => window.matchMedia(`(min-width: ${doc.getPropertyValue('--xs')})`),
  sm: () => window.matchMedia(`(min-width: ${doc.getPropertyValue('--sm')})`),
  md: () => window.matchMedia(`(min-width: ${doc.getPropertyValue('--md')})`),
  lg: () => window.matchMedia(`(min-width: ${doc.getPropertyValue('--lg')})`),
  xl: () => window.matchMedia(`(min-width: ${doc.getPropertyValue('--xl')})`),
  sm_dn: () =>
    window.matchMedia(`(max-width: ${doc.getPropertyValue('--sm_dn')})`),
  md_dn: () =>
    window.matchMedia(`(max-width: ${doc.getPropertyValue('--md_dn')})`),
  lg_dn: () =>
    window.matchMedia(`(max-width: ${doc.getPropertyValue('--lg_dn')})`),
  xl_dn: () =>
    window.matchMedia(`(max-width: ${doc.getPropertyValue('--xl_dn')})`),
};
