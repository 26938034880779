import { addEvents } from '~utils';

export default function () {
  const toggle = this;
  const listing = this.parentNode;

  let params = new URLSearchParams(window.location.search);
  let listToggleQs = params.get('listToggle');
  if (listToggleQs !== null) {
     listing.dataset.view = listToggleQs;
  }
  let listingStart = listing.dataset.view;
  const buttons = toggle.querySelectorAll('.list-toggle__item');
  listingStart === 'grid' ? buttons[0].classList.add('is-active') : buttons[1].classList.add('is-active');
  document.querySelector('.list-tools__list-toggle').value = listing.dataset.view;
  buttons.forEach(el => {
    addEvents(el, 'click.prevent enter', e => {
      buttons.forEach(el => el.classList.remove('is-active'));
      el.classList.add('is-active');
      const option = el.dataset.toggle 
      option === 'grid' ? listing.dataset.view = 'grid' : listing.dataset.view = 'list'
      document.querySelector('.list-tools__list-toggle').value = listing.dataset.view;
      var queryParams = new URLSearchParams(window.location.search);
      queryParams.set("listToggle", option);      
      window.location.search = queryParams.toString();
    })
  });
  
}
