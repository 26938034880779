import { toggle } from '~plugins';

export default function () {
  // :scope allows for the relations attr to still work
  const items = [...this.querySelectorAll(':scope > .accordion__item')];
  const relations = [];

  items.forEach(container => {
    let trigger = container.querySelector('.accordion__trigger');
    let content = container.querySelector('.accordion__content');
    let config = {
      container,
      trigger,
      content,
      // relations,
    };
    relations.push(new toggle(config));
  });
}
