const DOMContentLoaded = async callback => {
    let domLoading = true;
    do {
      if (document.readyState === 'complete') {
        callback();
        domLoading = false;
      } else await new Promise(resolve => setTimeout(resolve, 50));
    } while (domLoading);
  };
  
  export default DOMContentLoaded;
  