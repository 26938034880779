import { addEvents } from '~utils';
export default function() {
  const mobileScreen = window.matchMedia('(max-width: 768px)');
  const searchToggle = this.querySelector('.js-header-search-toggle')
  const glyph = searchToggle.querySelector('i')
  // console.log(searchToggle)
  const headerSearch = this.querySelector('.header__search')
  const toggleElements = (delay = 0) => { 
    // this timeout is to allow the animation to finish before the class is toggled
    setTimeout(() => {
      glyph.classList.toggle('glyph-eye-glass')
      glyph.classList.toggle('glyph-remove')
    }, delay);
    this.classList.toggle('is-active')
  }
  if(!mobileScreen.matches) {
    // console.log('desktop')
    addEvents(searchToggle, 'click enter', () => {
      // console.log('desktop clicks')
      if(headerSearch.attributes['aria-hidden'].value === 'true') {
        headerSearch.attributes['aria-hidden'].value = 'false'
        setTimeout(() => {
          toggleElements(100)
          headerSearch.querySelector('.header__search-input').focus()
        }, 50);  
      } else {
        toggleElements(450)
        setTimeout(() => {
          headerSearch.attributes['aria-hidden'].value = 'true'
        }, 550);
      }
      
    });
  } else {
    console.log('mobile')
    headerSearch.attributes['aria-hidden'].value = 'false'
  }
}
