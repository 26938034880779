import {addEvents, setCookie} from '~utils';

export default function () {
  const cookies = this.querySelector('.cookie-notice__modal');
  const closeBtn = cookies.querySelector('.cookie-notice__modal-close');
  // The accept & decline buttons may be omitted depending on
  // server-side configurations.
  const accept = cookies.querySelector('.cookie-notice__modal-accept');
  const decline = cookies.querySelector('.cookie-notice__modal-decline');


  // If there is no Accept or Decline button, the banner is just being
  // used as a cookie notice, so let's record that the banner was closed
  // and keep it closed for `dismissCookieExpirationDays` days.
  const storeClosedIfNotificationMode = () => {
    if (accept || decline) return;
    let cookieName = this.props.dismissCookie;
    let cookieExpirationDays = this.props.dismissCookieExpirationDays ?? 30;
    setCookie(cookieName, true, cookieExpirationDays);
  }

  // Notice there's not an openPrompt() function. The FE code
  // trusts the backend to render the prompt when needed it so
  // initial rendering doesn't require the script to be loaded,
  // parsed, and executed. This improves CWV scores.
  const closePrompt = () => {
    this.classList.add('hidden');
    closeBtn.removeAttribute('tabindex');
    accept?.removeAttribute('tabindex');
    decline?.removeAttribute('tabindex');
    storeClosedIfNotificationMode();
  };

  // FE Note: It's important that we don't set consent status
  // in a temporary location like local storage for the
  // front-end site, because team's will forget to change how
  // its stored when integrating it in a website.
  // Real life story: we have a project that is still using 
  // localStorage in production, so that the back-end code never
  // renders the correct GTM & Analytics scripts for accepted visitors.
  // The temporary front-end solution masked the problem.
  const setResponse = async (allowNonessentialCookies) => {
    await fetch('/api/cookies/consent', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(allowNonessentialCookies),
    });

    this.dispatchEvent(new CustomEvent("consent-response", {
      detail: { allowNonessentialCookies: allowNonessentialCookies }
    }));
    closePrompt();
  };

  addEvents(closeBtn, 'click enter', closePrompt);
  if(decline)
  {
    addEvents(decline, 'click enter', () => setResponse(false));
  }
  if(accept)
  {
    addEvents(accept, 'click enter', () => setResponse(true));
  }
}


