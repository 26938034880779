export default function(els, wrap) {
  els = Array.isArray(els) ? [...els] : [els];
  wrap = typeof wrap == "string" ? document.createElement(wrap) : wrap;

  let parent = els[0].parentElement;

  if (els.every(el => el.parentElement === parent)) {
    els[0].parentElement.insertBefore(wrap, els[0]);
    els.forEach(el => wrap.appendChild(el));
  } else {
    console.error(
      "Multiple non-sibling elements passed to wrap. Collections of elements MUST be siblings."
    );
  }
  return wrap;
}
