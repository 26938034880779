import { toggle } from '~plugins';
import { addEvents } from '~utils';

export default function () {
  const tools = this;

  const select = () => {
    const items = [...tools.querySelectorAll('.list-tools__select')];
    const relations = [];
    // county cookie 
    const setCountyCookie = (val, event) => {
      if (!val || val === 'clear') {
        event.preventDefault()
        clearActive(event.target)
        document.cookie = "county=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
        return
      }
      let expire = new Date()
      let cookieValue = encodeURIComponent(val)
      expire.setTime((new Date()).getTime() + 3600000 * 24 * 365)
      document.cookie = "county=" + cookieValue + ";expires=" + expire.toGMTString() + "; path=/"
    }
    const clearActive = (activeItem) => {
      // removes active class from all items
      // sets active class on clicked item
      tools.querySelector('.list-tools__select-link--active').classList.remove('list-tools__select-link--active')
      activeItem.classList.add('list-tools__select-link--active')
      tools.querySelector('.list-tools__select-active').innerText = activeItem.innerText
    }
    if (tools.classList.contains('list-tools--county')) {
      const countyCookie = document.cookie.split(';').find(c => c.trim().startsWith('county='))
      let countyItems = [...tools.querySelectorAll('.list-tools__select-link')]
      if (countyCookie) {
        const activeItem = tools.querySelector(`[data-county-id="${countyCookie.split('=')[1]}"]`)
        clearActive(activeItem)
      }
      countyItems.forEach(item => {
        addEvents(item, 'click enter', () => {
          setCountyCookie(item.dataset.countyId, event)
        })
      })
    }

    let allFilterButtons = [...tools.querySelectorAll('.list-tools__select-link')]
    let searchBox = tools.querySelector('.list-tools__search-input');
    let submitButton = tools.querySelector('.list-tools__search-submit');
    let listToggle = document.querySelector('.list-tools__list-toggle');
    allFilterButtons.forEach(button => {
      button.addEventListener('click', (e) => {
        if (searchBox) {
          e.preventDefault();

          var url = new URL(button.href);
          url.searchParams.set("textSearch", searchBox.value);
          url.searchParams.set("page", 1);
          window.location = url;
        }
      });
    });

    if (searchBox !== null && submitButton !== null) {
      submitButton.addEventListener('click', (e) => {
        e.preventDefault();
        const url = new URL(allFilterButtons[0].href);
        const params = new URLSearchParams(url.search);
        params.set('textSearch', searchBox.value);
        params.set('page', 1);
        window.location.search = params.toString();
      });
    }

    function arrayEquals(a, b) {
      return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
    }

    // Migrate all this to a dynamic file
    const isFilter = tools.classList.contains('list-tools--filter')
    if (isFilter) {
      let labelEl = tools.querySelector('.list-tools__select-active')
      const filterTextDefault = labelEl.innerText
      const filterContent = (val) => {
        if (val !== 'all') {
          filterEl.classList.add('is-filtered')
          filterItems.forEach(item => {
            let valList = val.split(";");
            valList = valList.sort();
            let filterTypeList = item.dataset.filterType.split(";");
            filterTypeList = filterTypeList.sort();
            if (valList.length > 1) { // multiple filters
              const intersectionList = [...new Set(valList.filter(element => filterTypeList.includes(element)))]

              //Check if there is at least 1 intersection, this is an or filter.
              if (!intersectionList || intersectionList.length === 0) {
                item.classList.remove('is-active')
              }
              else {
                item.classList.add('is-active')
              }
            }
            else { // single filter
              filterTypeList.forEach(filterType => {
                if (val === filterType && !item.classList.contains('is-active')) {
                  item.classList.add('is-active')
                }
                else if (val === filterType && item.classList.contains('is-active')) {
                  item.classList.remove('is-active')
                }
              });
            }
          })
        } else { // all
          // no filters
          filterEl.classList.remove('is-filtered')
          filterItems.forEach(item => {
            item.classList.remove('is-active')
          })
          labelEl.innerText = filterTextDefault
        }

      }
      const checkEmpty = (array) => {
        let inactive = true
        for (let i = 0; i < array.length; i++) {
          const element = array[i];
          if (element.classList.contains('list-tools__select-link--active')) {
            inactive = false
            break
          }
        }
        if (inactive) {
          setAll()
        }
      }
      const setAll = () => {
        filterButtons[0].click()
      }
      let filterEl = this.nextElementSibling.classList.contains('is-filterable') ? this.nextElementSibling : null
      let filterItems = filterEl.querySelectorAll('[data-filter-type]')
      let filterButtons = [...tools.querySelectorAll('.list-tools__select-link')]
      filterButtons.forEach(button => {
        const filter = button.dataset.filter
        addEvents(button, 'click.stop.prevent enter.stop.prevent', () => {
          const buttonText = button.innerText
          if (!labelEl.innerText.includes(buttonText)) {
            labelEl.innerText.includes(filterTextDefault) ? labelEl.innerText = buttonText : labelEl.innerText += `, ${buttonText}`
          } else {
            labelEl.innerText = labelEl.innerText.replace(`, ${buttonText}`, '')
          }
          filter === 'all' ? filterButtons.forEach(b => b.classList.remove('list-tools__select-link--active')) : filterButtons[0].classList.remove('list-tools__select-link--active')
          button.classList.contains('list-tools__select-link--active') ? button.classList.remove('list-tools__select-link--active') : button.classList.add('list-tools__select-link--active')
          let filterAllActiveItems = [...tools.querySelectorAll('.list-tools__select-link--active')];
          let newFilter = "";
          filterAllActiveItems.forEach(element => {
            if (newFilter === "") {
              newFilter = element.dataset.filter;
            }
            else {
              newFilter = newFilter + ";" + element.dataset.filter;
            }
          });
          filterContent(newFilter)
          checkEmpty(filterButtons)
        })
      })
    }
    // end dynamic file
    const closeAll = e => {
      if (e.target.closest('.list-tools__select')) return;
      relations.forEach(r => r.close());
    };

    items.forEach(container => {
      let trigger = container.querySelector('.list-tools__select-active');
      let content = container.querySelector('.list-tools__select-menu');
      let config = {
        container,
        trigger,
        content,
        relations,
      };
      let select = new toggle(config);
      relations.push(select);
    });

    window.addEventListener('click', closeAll);
  };
  select();
}
