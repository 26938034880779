import { addEvents, createElement } from '~utils';

export default function () {
  const container = this;

  const resizeTextarea = t => {
    const style = getComputedStyle(t);
    const border = parseInt(style.borderTop) + parseInt(style.borderBottom);
    t.style.height = '';

    t.style.height = `${t.scrollHeight + border}px`;
  };

  const formatSelect = s => {
    if (s.value.trim()) s.classList.remove('is-placeholder');
    else s.classList.add('is-placeholder');
  };

  const tooltips = i => {
    const tip = createElement({
      tag: 'small',
      attributes: { class: 'blue-form__tooltip' },
      html: `<span>${i.getAttribute('title')}</span>`,
    });
    i.closest('.form-field').insertAdjacentElement('afterbegin', tip);
  };

  const triage = e => {
    if (e.target.matches('textarea')) resizeTextarea(e.target);
    if (e.target.matches('select')) formatSelect(e.target);
  };

  container.querySelectorAll('input[title]').forEach(tooltips);
  container.querySelectorAll('select').forEach(formatSelect);
  container.querySelectorAll('textarea').forEach(resizeTextarea);

  addEvents(container, 'input', triage);
}
