import Setup from '~setup';
await Setup();

import Component from '~component';
import Global from '~global';
import '~styles/main';

new Global({
  file: 'using-mouse.js',
  selector: 'body',
});
new Global({
  file: 'print-button.js',
  selector: 'body',
});
// Excluding youtube-player because it impacts client-side performance
// and is not used by default in StarterKit projects.
// Add it back if your project requires it. - Mike Wills, 11/23/2022
// new Global({
//   file: 'youtube-player.js',
// });

new Component({
  name: 'app-alert',
  script: true,
  style: true,
});

new Component({
  name: 'app-article-card',
  script: false,
  style: true,
});
new Component({
  name: 'app-article-list-item',
  script: false,
  style: true,
});
new Component({
  name: 'app-grant-list',
  script: true,
  style: true,
});
new Component({
  name: 'app-person-list',
  script: false,
  style: true,
});
new Component({
  name: 'app-event-list-item',
  script: false,
  style: true,
});
new Component({
  name: 'app-header',
  script: true,
  style: true,
});
new Component({
  name: 'app-header-tools',
  script: true,
  style: true,
});

new Component({
  name: 'app-footer',
  script: true,
  style: true,
});

new Component({
  name: 'app-accordion',
  script: true,
  style: true,
});

new Component({
  name: 'app-dialog',
  script: true,
  style: true,
});

new Component({
  name: 'app-breadcrumbs',
});
new Component({
  name: 'app-document',
  script: false,
  style: true,
});
new Component({
  name: 'app-feature',
  script: true,
  style: true,
});
new Component({
  name: 'app-grant-chart',
  script: false,
  style: true,
});
new Component({
  name: 'app-rail-item',
  script: false,
  style: true,
});
new Component({
  name: 'app-blockquote',
  script: false,
  style: true,
});

new Component({
  name: 'app-list-tools',
  script: true,
  style: true,
});
new Component({
  name: 'app-county-map',
  script: true,
  style: true,
});
new Component({
  name: 'app-list-toggle',
  script: true,
  style: true,
});
new Component({
  name: 'app-tabs',
  script: true,
  style: true,
});
new Component({
  name: 'app-on-page-nav',
  script: true,
  style: true,
});
new Component({
  name: 'app-list-item',
  script: false,
  style: true,
});

new Component({
  name: 'app-pagination',
  script: false,
  style: true,
});

new Component({
  name: 'app-section-header',
  script: false,
  style: true,
});

new Component({
  name: 'app-title',
  script: false,
  style: true,
});

new Component({
  name: 'app-wysiwyg',
  script: false,
  style: true,
});

new Component({
  name: 'blue-form',
  script: true,
  style: true,
});

new Component({
  name: 'app-cookie-notice',
  script: true,
  style: true,
});
new Component({
  name: 'app-hero-banner',
  script: false,
  style: true,
});
new Component({
  name: 'app-services-feature',
  script: false,
  style: true,
});
new Component({
  name: 'app-video',
  script: true,
  style: true,
});

new Component({
  name: 'app-media-object',
  script: false,
  style: true,
});