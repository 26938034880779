import { processProps } from '~utils';

const hostStyles = `
:host,
:host *,
:host *::before, 
:host *::after {
  box-sizing: inherit;
}
`;

const makeObservers = () => {
  class Observers {
    static observers = [];
  }
  class Resize {
    constructor(elements, callback) {
      const observer = new ResizeObserver(callback);

      elements =
        Symbol.iterator in Object(elements) ? [...elements] : [elements];
      elements.forEach(e => observer.observe(e));

      Observers.observers.push(observer);
      return observer;
    }
  }

  class Intersection {
    constructor(elements, callback, options) {
      const observer = new IntersectionObserver(callback, options || {});

      elements =
        Symbol.iterator in Object(elements) ? [...elements] : [elements];
      elements.forEach(e => observer.observe(e));

      Observers.observers.push(observer);
      return observer;
    }
  }

  class Mutation {
    constructor(elements, callback, config = {}) {
      const observer = new MutationObserver(callback);
      config = { childList: false, attributes: true, ...config };
      if (!Object.entries(config).some(([_, value]) => value)) return false;

      elements =
        Symbol.iterator in Object(elements) ? [...elements] : [elements];
      elements.forEach(e => observer.observe(e, config));

      Observers.observers.push(observer);
      return observer;
    }
  }

  return {
    get records() {
      return Observers.observers;
    },
    Resize,
    Intersection,
    Mutation,
  };
};

class Component {
  name = null;
  directory = null;
  #module = null;
  constructor(options) {
    if (options.directory) {
      options.directory = options.directory.trim();
      options.directory = options.directory.endsWith('/')
        ? options.directory.slice(0, -1)
        : options.directory;
      options.directory = options.directory.startsWith('/')
        ? options.directory.slice(1)
        : options.directory;
    }
    this.#registerComponent(options);
  }
  async #importBundleAssets({ name, directory, style = true, script = true }) {
    const loadBundleStyles = () =>
      style
        ? import(
            /* webpackInclude: /components[\\/].*?index\.bundle\.scss/ */
            /* webpackMode: "eager" */
            `../../${
              directory ? `${directory}/` : ''
            }components/${name}/index.bundle.scss`
          ).catch(() => Promise.resolve())
        : Promise.resolve();

    const loadBundleScripts = () =>
      script
        ? import(
            /* webpackInclude: /components[\\/].*?index\.bundle\.js/ */
            /* webpackMode: "eager" */
            `../../${
              directory ? `${directory}/` : ''
            }components/${name}/index.bundle.js`
          )
            .then(module => (this.#module = module.default))
            .catch(() => Promise.resolve())
        : Promise.resolve();

    return Promise.all([loadBundleStyles(), loadBundleScripts()]);
  }
  async #importChunkAssets({ name, directory, style = true, script = true }) {
    const loadChunkStyles = () =>
      style
        ? import(
            /* webpackChunkName: "[request]" */
            /* webpackPrefetch: true */
            /* webpackPreload: true */
            /* webpackMode: "lazy" */
            /* webpackInclude: /components[\\/].*?index\.chunk\.scss/ */
            `../../${
              directory ? `${directory}/` : ''
            }components/${name}/index.chunk.scss`
          ).catch(() => Promise.resolve())
        : Promise.resolve();

    const loadChunkScripts = () =>
      script
        ? import(
            /* webpackChunkName: "[request]" */
            /* webpackPrefetch: true */
            /* webpackPreload: true */
            /* webpackMode: "lazy" */
            /* webpackInclude: /components[\\/].*?index\.chunk\.js/ */
            `../../${
              directory ? `${directory}/` : ''
            }components/${name}/index.chunk.js`
          )
            .then(module => (this.#module = module.default))
            .catch(() => Promise.resolve())
        : Promise.resolve();

    return Promise.all([loadChunkStyles(), loadChunkScripts()]);
  }
  async #registerComponent(options) {
    const { name, directory, baseElement = HTMLElement } = options;
    const registrar = this;
    registrar.name = name;
    registrar.directory = directory;

    await this.#importBundleAssets(options);

    customElements.define(
      name,
      class extends baseElement {
        registrar = null;
        observers = null;
        props = null;

        #processProps() {
          this.props = processProps(this);
        }

        constructor() {
          super();
          const slot = document.createElement('slot');
          const hostStylesheet = new CSSStyleSheet();
          hostStylesheet.replaceSync(hostStyles);

          this.attachShadow({ mode: 'open' });

          this.shadowRoot.append(slot);
          this.shadowRoot.adoptedStyleSheets = [hostStylesheet];

          this.registrar = registrar;
        }
        async connectedCallback() {
          this.#processProps();
          this.observers = makeObservers();

          await registrar.#importChunkAssets(options);
          if (registrar.#module) registrar.#module.apply(this);
          this.setAttribute('component', '');
        }
      }
    );
  }
}

export { Component, Component as default };
