/******************
    * This is a helper method to make adding events to elements easier.
    * It allows you to pass an element, a CSS selector string, or an array of elements/nodelist as the element parameter
    * It can take more than one event at a time, so you can wireup multiple events in a single line.
    * It also has short hand for comment events, like on press of enter, or press of escape
    * All events can be modified to prevent default or stop propagation
    * Usage Examples:
      this.$addEvents(element, 'click', function() { // stuff to do }) // add click to an element
      this.$addEvents(window, 'resize orientationchange load', function() { // stuff to do }) // add several events to window
      this.$addEvents(element, 'enter escape', function() { // stuff to do }) // add keyup -> enter and keyup -> escape to element
      this.$addEvents('.selector', 'click.prevent escape.stop.prevent', function() { // stuff to do }) // add click e.preventDefault() and escape e.stopPropagation() e.preventDefault() to all elements that match .selector
      
      // This example show to define a hover in and out method. It also allows you to wait until the user has actually intended to hover over an element before the function is run.
      this.$addEvents('h1', 'hover', {
        hover_delay: 500,
        sensitivity_delta: 10,
        sensitivity_delay: 100,
        in(e) {
          this.classList.add('is-hovered')
        },
        out(e) {
          this.classList.remove('is-hovered')
        }
      })
  ******************/
// class to build the event chain
class Hover {
  constructor(data) {
    return new Promise(async resolve => {
      this.data = {
        elem: data.elem,
        event: data.event,
        settings: {
          hover_delay:
            data.settings.hover_delay !== undefined
              ? data.settings.hover_delay
              : 200,
          sensitivity_delta:
            data.settings.sensitivity_delta !== undefined
              ? data.settings.sensitivity_delta
              : 10,
          sensitivity_delay:
            data.settings.sensitivity_delay !== undefined
              ? data.settings.sensitivity_delay
              : 100,
          out: data.settings.out,
        },
      };
      this.origin = { x: this.data.event.clientX, y: this.data.event.clientY };
      this.current = { ...this.origin };
      this.resolve_interval = null;
      this.update_interval = null;
      this.moving = false;
      this._resolve = null;

      this.data.elem.addEventListener('mousemove', this.watch_hover);
      this.data.elem.addEventListener('mouseleave', this.watch_leave);
      resolve(await this.await_result());
    });
  }
  watch_hover = e => {
    this.current = { x: e.clientX, y: e.clientY };
  };
  watch_leave = e => {
    this.clear(true);
  };
  do_out = e => {
    this.data.elem.removeEventListener('mouseleave', this.do_out);
    this.data.settings.out.call(this.data.elem, e);
  };
  check_delta() {
    return (
      Math.abs(this.origin.x - this.current.x) >
        this.data.settings.sensitivity_delta ||
      Math.abs(this.origin.y - this.current.y) >
        this.data.settings.sensitivity_delta
    );
  }
  await_result() {
    return new Promise(resolve => {
      this._resolve = resolve;
      this.update_interval = setInterval(() => {
        this.check_delta()
          ? ((this.moving = true), (this.origin = { ...this.current }))
          : (this.moving = false);
      }, this.data.settings.sensitivity_delay);

      this.resolve_interval = setInterval(() => {
        this.moving ? false : (this.clear(), this._resolve(true));
      }, this.data.settings.hover_delay);
    });
  }
  clear(disengaged = false) {
    clearInterval(this.resolve_interval);
    clearInterval(this.update_interval);
    this.data.elem.removeEventListener('mouseleave', this.watch_leave);
    this.data.elem.removeEventListener('mousemove', this.watch_hover);
    if (disengaged) this._resolve(false);
    else if (this.data.settings.out)
      this.data.elem.addEventListener('mouseleave', this.do_out);
  }
}
class EventChain {
  // Modifiers
  alt() {
    return new Promise((res, rej) =>
      this.event.altKey ? res(true) : rej(false)
    );
  }
  ctrl() {
    return new Promise((res, rej) =>
      this.event.ctrlKey ? res(true) : rej(false)
    );
  }
  immediate() {
    return new Promise(
      res => (this.event.stopImmediatePropagation(), res(true))
    );
  }
  key(key) {
    return new Promise(res =>
      this.event.key === key ? res(true) : res(false)
    );
  }
  native() {
    return Promise.resolve(true);
  }
  prevent() {
    return new Promise(res => (this.event.preventDefault(), res(true)));
  }
  self() {
    return new Promise((res, rej) =>
      this.event.target === this.elem ? res(true) : rej(false)
    );
  }
  shift() {
    return new Promise((res, rej) =>
      this.event.shiftKey ? res(true) : rej(false)
    );
  }
  stop() {
    return new Promise(res => (this.event.stopPropagation(), res(true)));
  }
  unshift() {
    return new Promise((res, rej) =>
      this.event.shiftKey ? rej(false) : res(true)
    );
  }

  // Event Shortcuts
  enter() {
    return new Promise((res, rej) =>
      this.event.key == 'Enter' ? res(true) : rej(false)
    );
  }
  escape() {
    return new Promise((res, rej) =>
      this.event.key == 'Escape' ? res(true) : rej(false)
    );
  }
  space() {
    return new Promise((res, rej) =>
      this.event.key == ' ' ? res(true) : rej(false)
    );
  }
  tab() {
    return new Promise((res, rej) =>
      this.event.key == 'Tab' ? res(true) : rej(false)
    );
  }
  hover() {
    return new Hover({
      elem: this.elem,
      event: this.event,
      settings: this.settings,
    });
  }

  async process(m, k) {
    let method = typeof this[m] === 'function' ? m : 'native';
    return await this[method](k);
  }
  async validateKeyCodes(keyCodes) {
    let results = [];
    results.push(
      await keyCodes[1].reduce(async (prevPromise, nextKey, i) => {
        let match = await prevPromise;
        i > 0 ? results.push(match) : false;
        return this.process(keyCodes[0], nextKey);
      }, Promise.resolve(false))
    );
    return results.some(r => r) ? Promise.resolve(true) : Promise.reject(false);
  }
  getKeyCodes(item) {
    const keyRegex = new RegExp(/^(key)\[(.*?)\]$/, 'g');
    const result = keyRegex.exec(item);
    if (result) return [result[1], result[2].split(',')];
    return false;
  }
  async validate(chain) {
    try {
      let valid = await chain.reduce(async (prevPromise, nextItem) => {
        await prevPromise;
        let keyCodes = this.getKeyCodes(nextItem);
        if (keyCodes) return this.validateKeyCodes(keyCodes);
        return this.process(nextItem);
      }, Promise.resolve());
      return valid;
    } catch (err) {
      return false;
    }
  }
  get type() {
    switch (this.event_array[0]) {
      case 'enter':
      case 'escape':
      case 'space':
      case 'tab':
        return 'keydown';
      case 'hover':
        return 'mouseover';
      default:
        return this.event_array[0];
    }
  }
  set create(data) {
    this.elem = data.this;
    this.event = data.event;
    if (typeof data.data === 'object') {
      this.settings = data.data;
      this.method = data.data.in;
    } else {
      this.method = data.data;
    }
    return (async () =>
      (await this.validate(this.event_array)) && this.method
        ? this.method.call(this.elem, this.event)
        : false)();
  }
  constructor(event_string) {
    this.event_array = event_string.split('.');
  }
}

// wire up the events with the compiled event chains
export default function (ele, events, data) {
  const elements =
    typeof ele == 'string'
      ? [...document.querySelectorAll(ele)]
      : NodeList.prototype.isPrototypeOf(ele)
      ? [...ele]
      : Array.isArray(ele)
      ? ele
      : [ele];
  events = events.split(/[\s]/gi);
  if (events.includes('once')) {
    events.splice(events.indexOf('once'), 1);
    elements.forEach(typeof data === 'object' ? data.in : data);
  }
  elements.forEach(element => {
    events.forEach(event_string => {
      let eventChain = new EventChain(event_string);
      element.addEventListener(eventChain.type, function (event) {
        eventChain.create = { event, data, this: this };
      });
    });
  });
}
