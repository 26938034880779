import { addEvents } from '~utils';

export default function () {
  console.log('County Map');
  const countyCookie = document.cookie.split(';').find(c => c.trim().startsWith('county='))
  const map = this.querySelector('.county-map__svg')
  const countyTab = document.querySelector('.tabs--county')
  const countyWrapper = document.querySelector('.county')
  if(countyTab) {
    const tabToggle = this.querySelectorAll('.js-tab-toggle')
    console.log(tabToggle)
    tabToggle.forEach((btn) => {
      addEvents(btn, 'click.stop.prevent enter.stop.prevent', () => {
        console.log('click')
          const tab = btn.getAttribute('data-tab')
          document.getElementById(tab).click()
          if(countyWrapper && countyWrapper.classList.contains('county--is-inactive')) {
            this.classList.add('county-map--small')
            countyWrapper.classList.remove('county--is-inactive')
            countyWrapper.classList.add('county--is-active')
            
          }
        })
    })
  }
      
  if(countyCookie) {
    const activeCounty = map.getElementById(`${countyCookie.split('=')[1]}`)
    activeCounty.classList.add('is-active')
  }
}
