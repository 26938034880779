export default function() {
    const linksContainer = document.querySelector('.on-page-nav--events .on-page-nav__inner');
    if(linksContainer === null) return;
    
    const links = linksContainer.querySelectorAll('.on-page-nav__link');
    const nextArrow = linksContainer.querySelector('.on-page-nav__next');
    const prevArrow = linksContainer.querySelector('.on-page-nav__prev');

    nextArrow.addEventListener('click', () => {
        const activeLink = linksContainer.querySelector('.is-active');
        const nextLink = activeLink.nextElementSibling;
        if (nextLink) {
            activeLink.classList.remove('is-active');
            nextLink.classList.add('is-active');
        } else {
            activeLink.classList.remove('is-active');
            links[0].classList.add('is-active');
        }
    });

    prevArrow.addEventListener('click', () => {
        const activeLink = linksContainer.querySelector('.is-active');
        const prevLink = activeLink.previousElementSibling.nodeName == "A" ? activeLink.previousElementSibling : links[links.length - 1];
        if (prevLink) {
            activeLink.classList.remove('is-active');
            prevLink.classList.add('is-active');
        } else {
            activeLink.classList.remove('is-active');
            links[links.length - 1].classList.add('is-active');
        }
    });
}