export default function () {
  if (Array.prototype.regionalEach !== undefined) {
    console.warn(
      '<BM Polyfill> Array.regionalEach is over writing default browser behavior. This polyfill should be renamed, or removed if browser functionality replaces it.'
    );
  }
  Array.prototype.regionalEach = function (fn) {
    const context = this;
    if (!Array.isArray(context))
      return Error(`${context} is not of type <Array>`);

    const matrix = context.reduce((matrix, item, i, array) => {
      const set = [item, array[i + 1], array[i - 1]];
      matrix.push(set);
      return matrix;
    }, []);

    matrix.every((set, index) => {
      const [current, next, previous] = set;
      const first = context.at(0);
      const last = context.at(-1);
      const region = {
        current,
        next,
        previous,
        first,
        last,
      };

      const proceed = fn.call(window, region, index, context);
      return proceed === undefined ? true : proceed;
    });
  };
  return Promise.resolve();
}
