// util function to convert the input to string type
function convertToString(input) {
  if (input) {
    if (typeof input === "string") {
      return input;
    }
    return String(input);
  }
  return "";
}

// convert string to words
function toWords(input) {
  let regex = /[A-Z\xC0-\xD6\xD8-\xDE]?[a-z\xDF-\xF6\xF8-\xFF]+|[A-Z\xC0-\xD6\xD8-\xDE]+(?![a-z\xDF-\xF6\xF8-\xFF])|\d+/g;
  return convertToString(input).match(regex).map(w => w.toLowerCase());
}

// convert the input array to camel case
function toCamelCase(words) {
  return words.reduce((res, str, i) => {
    res += i !== 0 ? str[0].toUpperCase() + str.slice(1) : str;
    return res
  }, "")
}

// this function call all the other string functions
export default function(input) {
  return toCamelCase(toWords(input));
}