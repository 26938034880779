import { DOMContentLoaded } from '~utils';

class Global {
  #module = null;
  constructor(options) {
    if (options.directory) {
      options.directory = options.directory.trim();
      options.directory = options.directory.endsWith('/')
        ? options.directory.slice(0, -1)
        : options.directory;
      options.directory = options.directory.startsWith('/')
        ? options.directory.slice(1)
        : options.directory;
    }
    options.file = options.file.trim();
    options.file = options.file.endsWith('.js')
      ? options.file.slice(0, -3)
      : options.file;
    options.file = options.file.startsWith('/')
      ? options.file.slice(1)
      : options.file;
    this.#wireGlobalScript(options);
  }

  async #wireGlobalScript({
    file,
    directory,
    selector = document.documentElement,
  }) {
    const elements =
      typeof selector == 'string'
        ? [...document.querySelectorAll(selector)]
        : NodeList.prototype.isPrototypeOf(selector) ||
          Symbol.iterator in Object(selector)
        ? [...selector]
        : Element.prototype.isPrototypeOf(selector)
        ? [selector]
        : false;

    const importGlobalScript = () =>
      import(
        /* webpackInclude: /global[\\/]js[\\/].*?\.js/ */
        /* webpackMode: "eager" */
        `../../${directory ? `${directory}/` : ''}global/js/${file}.js`
      )
        .then(module => (this.#module = module.default))
        .catch(() => Promise.resolve());

    if (elements.length) {
      await importGlobalScript();

      DOMContentLoaded(() => {
        elements.forEach(e => this.#module.apply(e));
      });
    }
  }
}

export { Global, Global as default };
